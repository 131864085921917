import React from "react";
import { Spin } from "antd";

class Loading extends React.Component {
  render() {
    return (
      <div style={{ height: '100vh', width: '100vw' }} className="flex items-center justify-center flex-col">
        <Spin />
        Loading...
      </div>
    );
  }
}

export default Loading;
