import axios from 'axios'
import { LocalStore } from "../utils/LocalStore"
import { API_BASE_URL } from './const'

export default class HttpClient {}

const token = LocalStore.getInstance().read('access_token');

export const httpClient = axios.create({
  baseURL: 'https://kho.bi.gobizdev.com',
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + encodeURI(token),
    'Access-Control-Allow-Origin': '*'
  },
});
