import React from "react";
import { Link } from "react-router-dom";
import { CalendarOutlined, DeploymentUnitOutlined } from "@ant-design/icons";
import { Menu } from "antd";
export default function MenuComponent({activeMenu}) {
  return (
    <div>
        {/* <div className="logo flex justify-center pt-3 mb-3">
            <Link to={"/"}>
                <img
                    className="logo--response"
                    src={
                        "https://gobiz.vn/wp-content/themes/gobiz/fav/favicon-32x32.png"
                    }
                    alt={"Olympus"}
                />
            </Link>
        </div> */}
        <div>
            <Menu
                theme="dark"
                selectedKeys={activeMenu}
                defaultSelectedKeys={["1"]}
                mode="inline"
                defaultOpenKeys={["report"]}
            >
                <Menu.Item key={'1'} icon={<CalendarOutlined />}>
                    <Link to={"/"}>
                        Dashboard
                    </Link>
                </Menu.Item>

                <Menu.SubMenu
                    icon={<DeploymentUnitOutlined />}
                    title="Org Chart"
                    key={"report"}
                >
                    <Menu.Item key={'functional_org_chart'}>
                        <Link to={"/org/functional"}>
                            Khối chức năng
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={'project_org_chart'}>
                        <Link to={"/org/projects"}>
                            Khối Sản phẩm & Dịch vụ
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to={"/org/departments"}>
                            Khối Phòng ban
                        </Link>
                    </Menu.Item>
                </Menu.SubMenu>
            </Menu>
        </div>
    </div>
  );
}
