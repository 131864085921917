import React, {useEffect, useState} from 'react';
import StandardLayout from "../../layouts/StandardLayout";
import {Input, Space, Table, Tag} from "antd";
import EmployeeService from "../../services/hrm/employeeService";
import SelectDepartment from "../components/SelectDepartment";
import DepartmentService from "../../services/hrm/departmentService";

//TODO: chưa handle các trường hợp lỗi, chưa thông báo khi thành công,... trang này chỉ mang tính chất tạm thời

const EmployeeList = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [name, setName] = useState();
    const [pagination, setPagination] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [departments, setDepartments] = useState();

    const columns = [
        {
            title: 'Mã',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: 'Họ và tên',
            dataIndex: 'name',
            key: 'name',
            render: (index, item) => {
                return <>
                    <img className={'small-avatar'} src={item.avatar}/>
                    <span>{item.name}</span>
                </>
            }
        },
        {
            title: 'Chức danh',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'SĐT',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: 'Phòng ban',
            dataIndex: 'departments',
            key: 'departments',
            render: (index, item) => {
                // Hiển thị các phòng ban hiện đang trực thuộc
                // Hiển thị nút Assign thêm
                return <>
                    { item.departments.map((department)=> {
                        return <Tag key={department.code}>{department.name}</Tag>
                    }) }

                    <SelectDepartment selected={item.departments} data={departments} handleOk={(selected, added, removed)=> {
                        console.info(added, removed);
                        added.forEach((dep)=>{
                            EmployeeService.addDepartment(item.code, dep.code)
                        });
                        removed.forEach((dep)=>{
                            EmployeeService.removeDepartment(item.code, dep.code)
                        });

                        item.departments = selected;

                        setData([...data])
                    } }></SelectDepartment>
                </>
            }
        }
    ];

    const fetchData = async () => {
        setLoading(true);
        try {
            let response = await EmployeeService.getEmployees({name: name, page: currentPage - 1, sort: 'code:ASC'});
            setData(response.data);
            let pageData = {
                pageSize: response.headers.get('x-page-size'),
                total: response.headers.get('x-total-count'),
                current: 1 + parseInt(response.headers.get('x-page-number')),
                showSizeChanger: false
            };
            // console.info(response.headers);
            setPagination(pageData);
        } catch {
        } finally {
            setLoading(false)
        }

    };

    const fetchDepartments = async () => {
        try {
            let response = await DepartmentService.getDepartmentTree();
            setDepartments(response.data)
        } catch {
        } finally {

        }
    };

    useEffect(() => {
        fetchData()
    }, [currentPage]);

    useEffect(()=> {
        fetchDepartments()
    }, []);

    return <StandardLayout title={'Project Org Chart'}>
        <h3 className='font-medium'>Tìm kiếm</h3>
        <Space className='mt-1'>
            <Input placeholder={'Họ tên'} value={name} onChange={(v) => {
                setName(v.target.value)
            }} onPressEnter={() => {
                fetchData()
            }}/>
        </Space>
        <Table loading={loading} className='mt-3' dataSource={data}
               rowKey={'id'} columns={columns} pagination={
            {
                ...pagination,
                onChange: (page, pageSize)=> {
                    setCurrentPage(page)
                }
            }
        }

        />
    </StandardLayout>
};

export default EmployeeList;