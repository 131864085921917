import { Route } from "react-router-dom";
import { LocalStore } from "../utils/LocalStore";
import React from "react";
import jwtDecode from "jwt-decode";
import { LOGIN_URL } from "../services/const";
import { Spin } from "antd";

function parseQueryStringToObject(queryString) {
    if (queryString && queryString.indexOf('?') > -1) {
        queryString = queryString.replace('?', '')
    }

    let params = {}, queries, temp, i, l;
    // Split into key/value pairs
    queries = queryString.split("&");
    // Convert the array of strings into an object
    for (i = 0, l = queries.length; i < l; i++) {
        temp = queries[i].split('=');
        params[temp[0]] = temp[1];
    }
    return params;
}

export default class PrivateRoute extends React.Component {
    componentDidMount() {
        let queryString = window.location.search || '';
        if (queryString.indexOf('?') > -1) {
            queryString = queryString.replace(/\?/g, '')
        }

        const params = parseQueryStringToObject(queryString);

        if (params && params['access-token']) {
            LocalStore.getInstance().save('access_token', decodeURI(params['access-token']))

            if (params['id_token']) {
                let jwt = params['id_token'];
                let userData = jwtDecode(jwt);
                LocalStore.getInstance().save('user', userData)
            }

            window.location = '/';
        }
    }

    render() {
        const { component: Component, RouteKey, location, ...rest } = this.props;
        const Key = RouteKey ? location.pathname + location.search : null;

        let access_token = LocalStore.getInstance().read('access_token');

        if (!access_token) {
            window.location.href = LOGIN_URL
            return <Spin />
        }

        return (
            <Route
                exact={true}
                {...rest} key={Key}
                render={props => {
                    return (
                        <Component {...props} />
                    )
                }} />
        );
    }
}