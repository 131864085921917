import { httpClient } from '../HttpClient'

export default class ReportService {
  static getKPI(query) {
    Object.keys(query).forEach((k) => {
      if (!query[k]) delete query[k]
    })
    const qs = new URLSearchParams(query).toString()
    return httpClient.get(`/detail/search?${qs}`)
  }
}
