import React from 'react'
import {OrganizationGraph} from "@ant-design/graphs";
import StandardLayout from "../../layouts/StandardLayout";
import useGoogleSheets from "use-google-sheets";
import {Common} from "./common";

const Projects = () => {
    const org = {
        id: 'joel',
        value: {
            name: 'Ubox Holding',
            title: 'CEO Nguyễn Văn Giang',
            // 建议使用 bae64 数据
            icon: 'https://f29-org-zp.zdn.vn/5db4b8ad565abc04e54b.jpg',
        }
    };

    const {data, loading, error} = useGoogleSheets({
        apiKey: 'AIzaSyAHF_aw06wUwo9GsZv3bwAoHWTDh2VKxKE',
        sheetId: '1GGB3uBgUgc0uuTEKDOUmETm_srJl5UzIz1JPaskRPSA',
        sheetsOptions: [{id: 'Project'},{id: 'Departments'}],
    });

    if (loading) {
        return <div>Loading</div>
    }

    if (error) {
        return <div>Error!</div>;
    }
    console.info(JSON.stringify(data));

    let sheet = data[0];

    Common.buildDepartmentNodes(data[1].data,'');
    org.children = Common.buildProjectNodes(sheet.data, '');

    const isShowMarker = (cfg) => {
        if (cfg.children && cfg.children.length) {
            return true
        }

        //TODO: tìm trong data tree xem có children thật không mới show
        return true
    };

    const formatNode = (node) => {
        if (node.id === 'joel') {
            return {
                fill: '#91d5ff',
                stroke: '#91d5ff',
            }
        }
        console.info(node);
        return {
            fill: node.color,
            textAlign: 'center'
        }
    };

    const formatLabel = (node, group, type) => {
        const styles = {
            icon: {
                width: 32,
                height: 32,
            },
            title: {
                fill: '#fff',
            },
            name: {
                fill: '#fff',
            },
        };

        const defautlStyles = {
            icon: {
                width: 32,
                height: 32,
            }
        };
        return node.id === 'joel' ? styles[type] : defautlStyles[type];
    };

    const config = {
        height: 750,
        level: 3,
        markerCfg: (cfg) => {
            console.info(cfg);
            return {
                position: 'right',
                show: isShowMarker(cfg),
                collapsed: true //TODO: chỉ hiển thị là đang không collapsed nếu có con
            };
        },
        layout: {
            direction: 'LR',
            getWidth: () => {
                return 75;
            }
        },
        autoFit: false,
        behaviors: ['drag-canvas', 'zoom-canvas', 'drag-node'],
    };

    return <StandardLayout title={'Project Org Chart'}>
        <OrganizationGraph
            {...config}
            data={org}
            nodeCfg={{
                style: formatNode,
                anchorPoints: [[0,0.5],[1,0.5]],
                autoWidth: true,
                label: {
                    style: formatLabel,
                }
            }}
        />
    </StandardLayout>
};

export default Projects;