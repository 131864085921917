import React, {useEffect, useState} from 'react'
import {DatePicker, Space, Table} from 'antd'
import moment from 'moment'
import {getUsername} from '../../layouts/Header'
import StandardLayout from '../../layouts/StandardLayout'
import ReportService from '../../services/report'

const Dashboard = (props) => {
    const [data, setData] = useState([])
    const [workingDate, setWorkingDate] = useState([moment(), moment()])
    const [loading, setLoading] = useState(true)

    const columns = [
        {
            key: 'working_date',
            title: <>Ngày làm việc<br/>工作日期</>,
            dataIndex: 'working_date',
            render: (index, item) => {
                return moment(item.working_date).format('DD/MM/YYYY')
            }
        },
        {
            key: 'ma_nhan_vien',
            title: <>Mã nhân viên<br/>工号</>,
            dataIndex: 'ma_nhan_vien',
        },
        {
            key: 'ten_phien_am',
            title: <>Tên nhân sự<br/>拼音</>,
            dataIndex: 'ten_phien_am',
        },
        {
            title: <>Bộ phận<br/>部门</>,
            dataIndex: 'bo_phan',
            key: 'bo_phan',
        },
        {
            key: 'so_kien_tao_tung_nguoi',
            title: <>Số kiện tạo<br/>创建的件数</>,
            dataIndex: 'so_kien_tao_tung_nguoi',
        },
        // {
        //     key: 'chuc_danh',
        //     title: <>Chức danh<br/>职位</>,
        //     dataIndex: 'chuc_danh',
        // },
        {
            key: 'so_kien_kiem_view_tung_nguoi',
            title: <>Số kiện kiểm view<br/>外观验货的件数</>,
            dataIndex: 'so_kien_kiem_view_tung_nguoi',
        },
        {
            key: 'so_kien_kiem_dem_tung_nguoi',
            title: <>Số kiện kiểm đếm<br/>验货点数的件数</>,
            dataIndex: 'so_kien_kiem_dem_tung_nguoi',
        },
        {
            key: 'so_kien_kiem_chi_tiet_tung_nguoi',
            title: <>Số kiện kiểm chi tiết<br/>详细验货的件数</>,
            dataIndex: 'so_kien_kiem_chi_tiet_tung_nguoi',
        },
        {
            key: 'so_kg_xuat_tung_nguoi',
            title: <>Số kg xuất<br/>出货的公斤数</>,
            dataIndex: 'so_kg_xuat_tung_nguoi',
        },
        {
            key: 'so_kien_tach_tung_nguoi',
            title: <>Số kiện tách<br/>分货的件数</>,
            dataIndex: 'so_kien_tach_tung_nguoi',
        },
        {
            key: 'diem_tao_kien',
            title: <>Điểm tạo kiện<br/>打标分数</>,
            dataIndex: 'diem_tao_kien',
        },
        {
            key: 'diem_kiem_view_tung_nguoi',
            title: <>Điểm kiểm view<br/>外观验货的分数</>,
            dataIndex: 'diem_kiem_view_tung_nguoi',
        },
        {
            key: 'diem_kiem_dem_tung_nguoi',
            title: <>Điểm kiểm đếm<br/>验货点数的分数</>,
            dataIndex: 'diem_kiem_dem_tung_nguoi',
        },
        {
            key: 'diem_kiem_chi_tiet_tung_nguoi',
            title: <>Điểm kiểm chi tiết<br/>详细验货的分数</>,
            dataIndex: 'diem_kiem_chi_tiet_tung_nguoi',
        },
        {
            key: 'diem_tach',
            title: <>Điểm tách<br/>分货的分数</>,
            dataIndex: 'diem_tach',
        },
        {
            key: 'diem_dong_bao',
            title: <>Điểm đóng bao<br/>打包分数</>,
            dataIndex: 'diem_dong_bao',
        },
        {
            key: 'diem_in_tem_tu_dong',
            title: <>Điểm in tem tự động<br/>自动打标分数</>,
            dataIndex: 'diem_in_tem_tu_dong',
        },
        {
            key: 'tong_diem',
            title: <>Tỉ lệ hoàn thành công việc tạm tính<br/>预计工作完成率</>,
            dataIndex: 'tong_diem',
        },
        // {
        //     key: 'diem_kiem',
        //     title: <>Tổng điểm kiểm<br/>检查分数</>,
        //     dataIndex: 'diem_kiem',
        // },
        // {
        //     key: 'loai_hop_dong',
        //     title: <>Loại hợp đồng<br/>合同类型</>,
        // },
    ];

    const fetchData = () => {
        setLoading(true)
        ReportService.getKPI({
            from_date: workingDate[0].format('YYYY-MM-DD'),
            to_date: workingDate[1].format('YYYY-MM-DD'),
            staff: getUsername()
        }).then(res => {
            setData(res.data.data)
        }).finally(() => {
            setLoading(false)
        })
    };

    useEffect(() => {
        fetchData()
    }, [workingDate]);

    return (
        <StandardLayout {...props} title={'KPI Reports'}>
            <h3 className='font-medium'>Tìm kiếm</h3>
            <Space className='mt-1'>
                <DatePicker.RangePicker allowClear={false} value={workingDate}
                                        onChange={(value) => setWorkingDate(value)}/>
            </Space>

            <Table loading={loading} className='mt-3' dataSource={data} columns={columns} pagination={{pageSize: 25}}/>
        </StandardLayout>
    )
};


export default Dashboard
