import React from 'react';
import { DecompositionTreeGraph } from '@ant-design/graphs';
import useGoogleSheets from "use-google-sheets";
import StandardLayout from '../../layouts/StandardLayout'

const stroke = '#EA2F97';
const config = {
    height: 750,
    data: {},
    nodeCfg: {
        size: [180, 25],
        autoWidth: true,
        percent: {
            position: 'bottom',
            size: 4,
            style: (arg) => {
                return {
                    radius: [0, 0, 0, 2],
                    fill: arg.value.percent > 0.3 ? stroke : '#1f8fff',
                };
            },
        },
        items: {
            containerStyle: {
                fill: '#fff',
            },
            padding: 6,
            style: (cfg, group, type) => {
                const styles = {
                    icon: {
                        x: 185,
                        textAlign: 'right',
                        width: 12,
                        height: 12,
                    },
                    value: {
                        x: 180,
                        textAlign: 'right',
                        fill: '#f00',
                    },
                    text: {
                        fill: '#aaa',
                    },
                };
                return styles[type];
            },
        },
        nodeStateStyles: {
            hover: {
                lineWidth: 2,
            },
        },
        title: {
            containerStyle: {
                fill: 'transparent',
            },
            style: {
                fill: '#000',
                fontSize: 12,
            },
        },
        style: (arg) => {
            return {
                fill: '#fff',
                radius: 2,
                stroke: arg.value.percent > 0.3 ? stroke : '#1f8fff',
            };
        },
        // anchorPoints: [[0.5,0],[0.5,1]]
    },
    layout: {
        // direction: 'TB',
        getWidth: () => {
            return 100;
        },
        getHeight: () => {
            return 75;
        }
    },
    edgeCfg: {
        type: 'cubic',
        label: {
            style: {
                fill: '#aaa',
                fontSize: 12,
                fillOpacity: 1,
            },
        },
        style: (edge) => {
            return {
                stroke: '#518AD3',
                strokeOpacity: 0.5,
            };
        },
        endArrow: {
            fill: '#518AD3',
        },
        edgeStateStyles: {
            hover: {
                strokeOpacity: 1,
            },
        },
        // sourceAnchor:1,
        // targetAnchor:0
    },
    markerCfg: (cfg) => {
        return {
            position: 'right',
            show: cfg.children && cfg.children.length,
            style: (arg) => {
                return {
                    stroke: arg.value.percent > 0.3 ? stroke : '#1f8fff',
                };
            },
        };
    },
    behaviors: ['drag-canvas', 'zoom-canvas', 'drag-node'],
};

function buildNode (data) {
    return {
        id: data.Id,
        value: {
            title: data.ProjectName + ' - ' + data.Group,
            items: [
                {
                    text: `${data.Name} (${data.Unit})`
                },
                {
                    text: 'Mục tiêu',
                    value: data.Expected
                },
                {
                    text: 'Hiện tại',
                    value: data.Current ? data.Current : 'Chưa có số'
                }
            ],
            percent: data.Current ? parseFloat(data.Current.replaceAll(',',''))/parseFloat(data.Expected.replaceAll(',','')) : 0
        },
        color: '#FF0000'
    }
}

function buildChildren(key, data) {
    let children = [];
    for (let item of data) {
        if (item.Id && item.Parent === key) {
            let node = buildNode(item);
            node.children = buildChildren(item.Id, data);
            children.push(node)
        }
    }

    return children
}

const KPI = () => {
    const { data, loading, error } = useGoogleSheets({
        apiKey: 'AIzaSyAHF_aw06wUwo9GsZv3bwAoHWTDh2VKxKE',
        sheetId: '1I1SaaDDFzVnhBO6obdOOlOqiJPw6dVhVYdzffN63RSQ',
        sheetsOptions: [{ id: 'KPI' }],
    });

    if (loading) {
        return <div>Loading</div>
    }

    if (error) {
        return <div>Error!</div>;
    }
    console.info(JSON.stringify(data));

    let root;
    let sheet = data[0];
    for (let kpi of sheet.data) {
        //Nếu có id nhưng không có parent thì là root
        if (kpi.Id && !kpi.Parent) {
            root = buildNode(kpi);
            break;
        }
    }

    root.children = buildChildren(root.id, sheet.data);

    console.info(JSON.stringify(root));
    config.data = root;

    return <StandardLayout title={'KPI Reports'}>
        <DecompositionTreeGraph {...config} />;
    </StandardLayout>
};

export default KPI;
// export default class KPI extends React.Component {
//     componentDidMount() {
//     }
//
//     render() {
//
//     }
// }