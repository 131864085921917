export class Common {
    static getLevel(level) {
        switch (level) {
            case 'Khối':
                return 1;
            case 'Trung Tâm':
                return 2;
            case 'Phòng':
                return 4;
            case 'Ban':
                return 3;
            case 'Nhóm':
                return 5;
            default:
                return 6;
        }
    }

    static departments = [];

    static buildDepartmentNodes(data, parent, color) {
        let nodes = [];
        for (let department of data) {
            //Nếu có id nhưng không có parent thì là root
            if (department.Id && department.Parent === parent) {
                let dcolor = department.Color ? department.Color : color;

                let node = {
                    id: department.Id,
                    value: {
                        name: department.Name,
                        title: department.Head,
                        icon: department.Icon
                    },
                    children: Common.buildDepartmentNodes(data, department.Id, dcolor),
                    level: Common.getLevel(department.Level),
                    color: dcolor
                };
                Common.departments.push(node);
                nodes.push(node);
            }
        }

        return nodes
    }

    static buildProjectNodes(data, parent, color) {
        let nodes = [];
        for (let department of data) {
            //Nếu có id nhưng không có parent thì là root
            if (department.Id && department.Parent === parent) {
                let dcolor = department.Color ? department.Color : color;

                //region -- Lấy ở DEPT nếu như có ref sang DEPT tương ứng --
                let dept;
                if (department.Functional) {
                    dept = Common.departments.find(d=> d.id === department.Functional && d.level > 1)
                }
                let childNodes = Common.buildProjectNodes(data, department.Id, dcolor);
                if (childNodes.length === 0 && dept) {
                    childNodes = dept.children
                }
                //endregion

                let node = {
                    id: department.Id,
                    value: {
                        name: department.Name,
                        title: department.Head,
                        icon: department.Icon
                    },
                    children: childNodes,
                    level: Common.getLevel(department.Level),
                    color: dcolor
                };
                nodes.push(node);
            }
        }

        return nodes
    }

    //TODO: Xem Custom Content có thể để link sang danh sách nhân sự được hay không
    //TODO: Show danh sách nhân sự trong một phòng ban
}