import { hrmClient } from '../HrmClient'

export default class EmployeeService {
    static getEmployees(query) {
        Object.keys(query).forEach((k) => {
            if (!query[k]) delete query[k]
        });
        const qs = new URLSearchParams(query).toString();
        return hrmClient.get(`/employees/?${qs}`)
    }

    static addDepartment(employee, department, title, percentage) {
        return hrmClient.post(`/employees/${employee}/departments`, {
            departmentCode: department,
            title: title,
            percentage: percentage
        })
    }

    static removeDepartment(employee, department) {
        return hrmClient.delete(`/employees/${employee}/departments/${department}`)
    }
}