import { hrmClient } from '../HrmClient'

export default class DepartmentService {
    static getDepartments(query) {
        Object.keys(query).forEach((k) => {
            if (!query[k]) delete query[k]
        });
        const qs = new URLSearchParams(query).toString();
        return hrmClient.get(`/departments/?${qs}`)
    }

    static getDepartmentTree(query) {
        return hrmClient.get('/departments/tree')
    }
}