import React, {useState} from 'react'
import {Col, Modal, Row, Tag, Tree} from 'antd'
import {FolderFilled, FolderOpenFilled} from "@ant-design/icons";

const SelectDepartment = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selected, setSelected] = useState([...props.selected]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        console.info(selected, props.selected);
        let added = selected.filter(dep => {
            return props.selected.find((preselect)=> {
                return preselect.code === dep.code
            })==null
        });

        let removed = props.selected.filter(preselect => {
            return selected.find((dep)=> {
                return preselect.code === dep.code
            })==null
        });

        props.handleOk(selected, added, removed);
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const departmentMap = {};

    function convert(department) {
        departmentMap[department.code] = {
            code: department.code,
            name: department.name
        };

        return {
            title: department.name,
            key: department.code,
            children: department.children.map((dep) => {
                return convert(dep)
            }),
            switcherIcon: department.children.length > 0
                ? <FolderOpenFilled style={{color: getColorByLevel(department.level)}}/>
                : <FolderFilled style={{color: getColorByLevel(department.level)}}/>
        }
    }

    //TODO: thiết kế tạo sự khác biệt rõ ràng về icon cũng như màu sắc

    function getColorByLevel(level) {
        switch (level) {
            case 0:
                return '#FDBA37';
            case 1:
                return '#FDBB3E';
            case 2:
                return '#FDBC4B';
            case 3:
                return '#FDC75E';
            case 4:
                return '#FDD26A';
            case 5:
                return '#FDD472';
            case 6:
                return '#FDD47D';
            case 7:
                return '#FDDB8A';
            default:
                return '#FDBA37';
        }
    }

    let data;
    if (props.data) {
        data = props.data.map((dep) => {
            return convert(dep)
        });
    }

    return <>
        <a onClick={showModal}>[Chọn]</a>
        <Modal width={'800px'} title="Chọn phòng ban" open={isModalOpen} onOk={handleOk}
               onCancel={handleCancel}
               cancelButtonProps={null}>
            <Row>
                <Col span={12}>
                    <h4>Chọn phòng ban</h4>
                    <Tree treeData={data}
                        // fieldNames={{title: 'name', key: 'code', children: 'children'}}
                          height={450}
                          showLine
                          autoExpandParent={true}
                          defaultExpandAll={true}
                          onSelect={(selectedKeys) => {
                              if (selectedKeys.length && !selected.find(d => d.code === selectedKeys[0]))
                                  selected.push(departmentMap[selectedKeys[0]]);
                              setSelected(selected.filter(e=> true));
                          }}
                    />
                </Col>
                <Col span={12}>
                    <h4>Phòng ban đã chọn</h4>
                    {
                        selected.map((department) => {
                        return <Tag closable onClose={(e) => {
                            e.preventDefault();
                            let deselected = selected.filter(d => d.code !== department.code)
                            setSelected(deselected)
                        }
                        } key={department.code}>{department.name}</Tag>
                    })
                    }
                </Col>
            </Row>
        </Modal>
    </>
};

export default SelectDepartment;