import React, {useState} from 'react'
import { Button, Tree, Row, Col, Drawer, Select, Form, Input } from 'antd';
import StandardLayout from "../../layouts/StandardLayout";
import useGoogleSheets from "use-google-sheets";
import {Common} from "./common";

const Departments = () => {
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    const org = {
        id: 'joel',
        value: {
            name: 'Ubox Holding',
            title: 'CEO Nguyễn Văn Giang',
            // 建议使用 bae64 数据
            icon: 'https://avatars.githubusercontent.com/u/31396322?v=4',
        }
    };

    const {data, loading, error} = useGoogleSheets({
        apiKey: 'AIzaSyAHF_aw06wUwo9GsZv3bwAoHWTDh2VKxKE',
        sheetId: '1GGB3uBgUgc0uuTEKDOUmETm_srJl5UzIz1JPaskRPSA',
        sheetsOptions: [{id: 'Project'},{id: 'Departments'}],
    });

    if (loading) {
        return <div>Loading</div>
    }

    if (error) {
        return <div>Error!</div>;
    }
    console.info(JSON.stringify(data));

    let sheet = data[0];

    Common.buildDepartmentNodes(data[1].data,'');
    org.children = Common.buildProjectNodes(sheet.data, '');

    const isShowMarker = (cfg) => {
        if (cfg.children && cfg.children.length) {
            return true
        }

        //TODO: tìm trong data tree xem có children thật không mới show
        return true
    };

    const formatNode = (node) => {
        if (node.id === 'joel') {
            return {
                fill: '#91d5ff',
                stroke: '#91d5ff',
            }
        }
        console.info(node);
        return {
            fill: node.color,
            textAlign: 'center'
        }
    };

    const formatLabel = (node, group, type) => {
        const styles = {
            icon: {
                width: 32,
                height: 32,
            },
            title: {
                fill: '#fff',
            },
            name: {
                fill: '#fff',
            },
        };

        const defautlStyles = {
            icon: {
                width: 32,
                height: 32,
            }
        };
        return node.id === 'joel' ? styles[type] : defautlStyles[type];
    };

    const config = {
        height: 750,
        level: 3,
        markerCfg: (cfg) => {
            console.info(cfg);
            return {
                position: 'right',
                show: isShowMarker(cfg),
                collapsed: true //TODO: chỉ hiển thị là đang không collapsed nếu có con
            };
        },
        layout: {
            direction: 'LR',
            getWidth: () => {
                return 75;
            }
        },
        autoFit: false,
        behaviors: ['drag-canvas', 'zoom-canvas', 'drag-node'],
    };

    const treeData = [
        {
          title: 'parent 1',
          key: '0-0',
          children: [
            {
              title: 'parent 1-0',
              key: '0-0-0',
              disabled: true,
              children: [
                {
                  title: 'leaf',
                  key: '0-0-0-0',
                  disableCheckbox: true,
                },
                {
                  title: 'leaf',
                  key: '0-0-0-1',
                },
              ],
            },
            {
              title: 'parent 1-1',
              key: '0-0-1',
              children: [{ title: <span style={{ color: '#1890ff' }}>sss</span>, key: '0-0-1-0' }],
            },
          ],
        },
    ];
    
    const onFinish = () => {
        //todo
    }

    const onFinishFailed = () => {
        //todo
    }

    const optionsParentDepartment = () => {
        return [
            {code: 'PTSP', name: 'Phát triển sản phẩm'}, 
            {code: 'C&B', name: 'Hành chính nhân sự'},
            {code: 'HRM', name: 'Tuyển dụng'},
            {code: 'accounting', name: 'Kế toán'}
        ]
    }

    return <StandardLayout title={'Department Org Chart'}>
        <Row style={{backgroundColor: 'white', padding: 16}}>
            <Col span="20">
                <Tree
                    checkable
                    defaultExpandAll
                    // onSelect={onSelect}
                    // onCheck={onCheck}
                    treeData={treeData}
                />
            </Col>
            <Col span="4">
                <Button onClick={showDrawer}>
                    Thêm mới phòng ban
                </Button>
            </Col>
        </Row>
        <Drawer title="Thêm mới phòng ban" placement="right" onClose={onClose} open={open}>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Tên phòng ban"
                    name="departmentName"
                    rules={[{ required: true, message: 'Vui lòng nhập tên Bộ phận!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Mã Bộ phận"
                    name="departmentCode"
                    rules={[{ required: true, message: 'Vui lòng nhập mã Bộ phận' }]}
                >
                    <Input />
                </Form.Item>
                {/* Công ty | Khối | Trung Tâm | Ban | Phòng | Nhóm */}
                <Form.Item name="level" label="Level">
                    <Select>
                        <Select.Option value="company">Công ty</Select.Option>
                        <Select.Option value="block">Khối</Select.Option>
                        <Select.Option value="center">Trung Tâm</Select.Option>
                        <Select.Option value="team">Ban</Select.Option>
                        <Select.Option value="department">Phòng</Select.Option>
                        <Select.Option value="group">Nhóm</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item name="parents" label="Bộ phận cha">
                    <Select   
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Bộ phận cha"
                        // defaultValue={['a10', 'c12']}
                        onChange={() => {}}
                        // options={optionsParentDepartment}
                    >
                        {optionsParentDepartment().map(item => <Select.Option className={'_opt-parentCode'} value={item.code}
                                                    key={item.code}>{item.name}</Select.Option>)}
                    </Select>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    </StandardLayout>
};

export default Departments;