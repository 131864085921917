import React from 'react';
import routes from './Routes'
import './App.css';
import Loading from "./pages/Loading";
import ReactGA from "react-ga4";

class AppRootComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            initAppStatus: 'loading'
        };
    }

    componentDidMount() {
        //TODO: use env
        ReactGA.initialize("G-W4WT2CD4ZX");

        setTimeout(() => this.setState({
            initAppStatus: 'success'
        }), 1500);
    }

    render() {
        switch (this.state.initAppStatus) {
            case 'success':
                return (routes);

            case 'failure':
                return (
                    <span>Truy cập bị lỗi, vui lòng thử lại</span>
                );

            default:
                // show loading page
                return <Loading/>;
        }
    }
}


export default AppRootComponent;
