import React from 'react';
import asyncComponent from './utils/AsyncComponent'
import BetRoute from './pages/PrivateRoute'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import Page404 from './pages/404'
import Dashboard from './pages/dashboard';
import KPI from "./pages/objective/KPI"
import Functional from "./pages/organization/functional"
import Projects from "./pages/organization/projects"
import Employees from "./pages/organization/Employees";
import Departments from "./pages/organization/departments"

export default (
    <BrowserRouter>
        <Switch>
            <BetRoute exact path="/" component={Dashboard}/>
            <BetRoute exact path={"/company-kpi"} component={KPI}/>
            <BetRoute exact path={"/org/functional"} component={Functional}/>
            <BetRoute exact path={"/org/projects"} component={Projects}/>
            <BetRoute exact path={"/org/employees"} component={Employees}/>
            <BetRoute exact path={"/org/departments"} component={Departments}/>
            <Route path="*" component={Page404}/>
        </Switch>
    </BrowserRouter>
)
