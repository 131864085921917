import axios from 'axios'
import { LocalStore } from "../utils/LocalStore"
import { HRM_API_BASE_URL } from './const'

export default class HrmClient {}

const token = LocalStore.getInstance().read('access_token');

export const hrmClient = axios.create({
    baseURL: HRM_API_BASE_URL,
    timeout: 30000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // Authorization: 'Bearer ' + encodeURI(token),
        'Access-Control-Allow-Origin': '*'
    },
});
